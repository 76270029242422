<template>
  <div class="page">
    <!-- 正面是文字，反面是图片 -->
    <div class="card card-left">
      <div class="font"></div>
      <div class="back">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/1-1.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="font">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/1-2.png" />
        </div>
      </div>
      <div class="back">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/2-1.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="font">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/2-2.png" />
        </div>
      </div>
      <div class="back">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/3-1.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="font">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/3-2.png" />
        </div>
      </div>
      <div class="back">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/4-1.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="font">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/4-2.png" />
        </div>
      </div>
      <div class="back">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/5-1.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="font">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/5-2.png" />
        </div>
      </div>
      <div class="back">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/6-1.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="font">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/6-2.png" />
        </div>
      </div>
      <div class="back">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/7-1.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="font">
        <div class="img8">
          <img src="https://home/ResImg/xxqg/7-2.png" />
        </div>
      </div>
      <div class="back"></div>
    </div>
  </div>
  <!-- <div class="header">
     <h1 id="text">WebMessageTest</h1>
 </div> -->
</template>

<script>
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      pageNum: 1,
      keyStatus: false,
    };
  },

  mounted() {
    this.getPageInfo();
    // this.keyDown()
    console.log("window", window);
    // console.log(11111111111111);
    // setTimeout(() => {
    //   console.log(22222222222);
    // window.postMessage("SetGestureEventHandler=OnGestureRecognizer");
    // this.OnGestureRecognizer()
    // window.top.chrome.webview.postMessage("11111111111")
    // console.log('window.chrome',window.chrome);
    // console.log('window.chrome.webview',window.chrome.webview);
    // }, 1000);
    window.OnGestureRecognizer = this.OnGestureRecognizer;

    window.chrome.webview.postMessage(
      "SetGestureEventHandler=window.OnGestureRecognizer"
    );
  },

  methods: {
    getPageInfo() {
      // 调整z-index的值，让页面顺序显示，默认第一页在上面
      var runPage = document.querySelectorAll(".card");
      console.log("runPage", runPage);
      for (var i = 0, len = runPage.length; i < len; i++) {
        runPage[i].style.zIndex = len - i;
      }
      runPage[0].classList.add("left");
      // 模拟翻书
      // setTimeout(() => {
      //   runPage[0].classList.add('left')
      // }, 1000);

      // this.keyDown()

      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 =
          e || event || window.event || arguments.callee.caller.arguments[0]; //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        //左
        //左
        if (e1 && e1.keyCode == 39) {
          // 按下左箭头 上一页

          if (this.pageNum > 1) {
            this.pageNum--;
            runPage[this.pageNum].classList.remove("left");
            runPage[this.pageNum].classList.add("right");
            setTimeout(() => {
              runPage[this.pageNum].classList.remove("right");
            }, 500);
          } else {
            console.log("已经是到第一页了");
          }

          console.log("this.pageNum", this.pageNum);
        } else if (e1 && e1.keyCode == 37) {
          // 按下右箭头 下一页

          if (this.pageNum < len - 1) {
            runPage[this.pageNum].classList.add("left");
            this.pageNum++;
          } else {
            console.log("已经是到最后一页了");
          }

          console.log("this.pageNum", this.pageNum);
        }
      };
    },
    OnGestureRecognizer(s) {
      var runPage = document.querySelectorAll(".card");
      console.log("runPage", runPage);
      for (var i = 0, len = runPage.length; i < len; i++) {
        runPage[i].style.zIndex = len - i;
      }
      runPage[0].classList.add("left");
      console.log("OnGestureRecognizer:" + s);
      this.message = s;
      if (s === "BROWSER_FORWARD" && !this.keyStatus) {
        this.keyStatus = true;
        if (this.pageNum > 1) {
          this.pageNum--;
          runPage[this.pageNum].classList.remove("left");
          runPage[this.pageNum].classList.add("right");
          setTimeout(() => {
            runPage[this.pageNum].classList.remove("right");
            this.keyStatus = false;
          }, 500);
        } else {
          this.keyStatus = false;
          console.log("已经是到第一页了");
        }

        console.log("this.pageNum", this.pageNum);
      } else if (s === "BROWSER_BACK" && !this.keyStatus) {
        this.keyStatus = true;
        if (this.pageNum < len - 1) {
          runPage[this.pageNum].classList.add("left");
          this.pageNum++;
          setTimeout(() => {
            this.keyStatus = false;
          }, 500);
        } else {
          this.keyStatus = false;
          console.log("已经是到最后一页了");
        }

        console.log("this.pageNum", this.pageNum);
      }
    },
  },
};
</script>


<style scoped>
@import "../assets/test/test.css";
.card .back .img8 {
  position: absolute;
  margin-right: 0.01302rem;
  width: 100%;
  left: 0;
  top: 0;
}
</style>